<template>
    <div class="relative flex flex-col gap-6 py-8">
      <AddSongNavBar class="z-20" @search="handleSearchSong" @close="handleCloseSongNavBar" @resetEmptyInput="resetEmptyInput" :isCreateSession="isCreateSession" :isCreateGeneralVoting="isCreateGeneralVoting" :emptyInput="emptyInput" :selectedSongs="selectedSongs"/>
      <div v-if="!searchTerm && selectedSongs.length < 1" class="z-10">
        <p class="font-extrabold text-2xl px-8">Populares</p>
        <div class="overflow-popular-songs-container">
          <div :style="{'--nSongs': popularSongs.length}" class="popular-songs-container">
            <PopularSong 
              class="mt-4" 
              v-for="(popularSong, index) in popularSongs" 
              :key="index" 
              :song="popularSong.songName" 
              :artist="popularSong.artist" 
              :image="popularSong.image" 
              @click="handleSearchSongClick(popularSong)"
            />
          </div>
        </div>
        <div class="px-8 mt-4">
          <p class="font-extrabold text-2xl">Éxitos</p>
          <div class="flex flex-col gap-4 mt-4">
            <SongCard v-for="(hitSong, index) in hitSongs" :key="index" :song="hitSong.songName" :artist="hitSong.artist" :image="hitSong.image" @click="handleSearchSongClick(hitSong)"/>
          </div>
        </div>
      </div>
      <div v-else-if="!searchTerm && selectedSongs.length >= 1" class="flex flex-col gap-24 items-center mx-8" :class="{'z-20': !modalVisible}">
        <div class="flex flex-col gap-6">
          <p class="text-tp-yellow font-extrabold text-2xl">Canciones seleccionadas</p>
          <div class="flex flex-col gap-4">
            <p class="font-bold">({{ selectedSongs.length }}/{{ isCreateSession ? 3 : isCreateGeneralVoting ? 4 : null }})</p>
            <DJSelectedSong v-for="(song, index) in selectedSongs" :key="index" :index="index + 1" :name="song.songName" :artist="song.artist" :image="song.image" @deleteSong="handleDeleteSong"/>
          </div>
          <p class="text-tp-yellow" v-if="createSessionError">Debes seleccionar 3 canciones antes de crear la sesión.</p>
        </div>
        <ButtonSpinner :classes="'border-2 border-tp-light-green bg-tp-yellow py-2 px-20 w-max rounded-xl text-tp-black font-bold'" @click="handleCreate" text="Crear" :isLoading="isLoading" />
      </div>
      <div v-else>
        <SimpleSpinner v-if="searchLoading"/>
        <div class="px-8 mt-4">
          <div class="flex flex-col gap-4 mt-4">
            <SongCard class="cursor-pointer" v-for="(song, index) in searchSongs" :key="index" :song="song.songName" :artist="song.artist" :image="song.image" @click="handleSearchSongClick(song)" :class="{'z-20': !modalVisible}"/>
          </div>
        </div>
      </div>
      <AddVotesModal
        v-if="modalVisible"
        modalVisible="true"
        :votePlans=votePlans
        @submitVotePlan="handlePayment"
        @onCloseModal="closeModal"
        :class="[
          {'transition-opacity duration-300 opacity-100 z-20': modalVisible}, 
          {'opacity-0': !modalVisible}
        ]"
      />
    </div>
  </template>
  
  <script>
  import { onMounted, ref } from 'vue';
  import AddSongNavBar from '@/components/AddSong/AddSongNavBar.vue';
  import PopularSong from '@/components/AddSong/PopularSong.vue';
  import AddVotesModal from '@/components/Common/AddVotesModal.vue';
  import ButtonSpinner from '@/components/Common/ButtonSpinner.vue';
  import SongCard from '@/components/AddSong/SongCard.vue';
  import DJSelectedSong from '@/components/DJ/DJSelectedSong.vue';
  import SimpleSpinner from '@/components/Common/SimpleSpinner.vue';
  import { searchItunesTrack, getSuggestions } from '@/service/musicService';
  import { useRoute, useRouter } from 'vue-router';
  import { useVotePlans } from '@/composables/votePlans.js';
  import { precreateVote } from '@/service/djService';
  import { createRanking, getSessionsInfo } from '@/service/djService';
  
  export default {
    name: 'SpotifySearcher',
    components: {
      AddSongNavBar,
      AddVotesModal,
      PopularSong,
      SimpleSpinner,
      DJSelectedSong,
      SongCard,
      ButtonSpinner
    },
    props: {
      loungeDetails: Object
    },
    setup () {
      const TIME_SEARCH_WAIT = 300;
      const searchTerm = ref(null);
      const searchLoading = ref(false);
      const searchSongs = ref([]);
      const timerSearch = ref(null);
      const popularSongs = ref([]);
      const route = useRoute();
      const router = useRouter();
      const loungeId = route.params.id;
      const songSelected = ref(null);
      const isCreateSession = ref(false);
      const isCreateGeneralVoting = ref(false);
      const hitSongs = ref([]);
      const selectedSongs = ref([]);
      const emptyInput = ref(false);
      const isLoading = ref(false);
      const sessionsInfo= ref(null);
  
      if (route.name === 'DJCreateSession') {
        isCreateSession.value = true;
      } 
  
      if (route.name === 'DJCreateGeneralVoting') {
        isCreateGeneralVoting.value = true;
      }
  
      const handleSearchSongClick = (song) => {
        if (isCreateSession.value || isCreateGeneralVoting.value) {
          handleAddSong(song);
        } else {
          const songId = song.songId === undefined ? song.trackId : song.songId
          openModal(songId);
        }
      }
  
      const { votePlans } = useVotePlans();
  
      const handleSearchSong = async (value) => {
        if (searchTerm.value == '' || searchTerm.value == null) {
          searchLoading.value = true;
        }
  
        // reset timer
        if (timerSearch.value) {
          clearTimeout(timerSearch.value);
          timerSearch.value = null;
        }
  
        // set search term value (input from user)
        searchTerm.value = value;
  
        // control value of searchbar
        if (value == '' || value == null) {
          console.log(value);
          searchSongs.value = [];
          return;
        }
  
        timerSearch.value = setTimeout(() => {
          searchItunesTrack(value).then(response => {
            searchSongs.value = response;
            console.log(searchSongs.value)
          }).catch(err => {
            console.log(err);
            searchTerm.value = null;
          }).finally(() => {
            searchLoading.value = false;
          });
        }, TIME_SEARCH_WAIT);
      };
  
      const handleCloseSongNavBar = async () => {
        // clear timer
        if (timerSearch.value) {
          clearTimeout(timerSearch.value);
          timerSearch.value = null;
        }
  
        // reset searcher values
        searchTerm.value = null;
        searchSongs.value = [];
      };
  
      const modalVisible = ref(false);
  
      const openModal = (song) => {
        songSelected.value = song;
        modalVisible.value = true;
      };
  
      const closeModal = () => {
        modalVisible.value = false;
      };
  
      const handlePayment = (votePlan) => {
        console.log(votePlan)
      };
  
      const handleAddSong = (song) => {
        if (isCreateGeneralVoting.value && selectedSongs.value.length < 4 && !selectedSongs.value.find(s => s.songName === song.songName && s.artist === song.artist)) {
          selectedSongs.value.push(song)
          searchTerm.value = null
          searchSongs.value = []
          emptyInput.value = true;
        } else if (isCreateSession.value && selectedSongs.value.length < 3 && !selectedSongs.value.find(s => s.songName === song.songName && s.artist === song.artist)) {
          selectedSongs.value.push(song)
          searchTerm.value = null
          searchSongs.value = []
          emptyInput.value = true;
        }
      };
  
      const resetEmptyInput = () => {
        emptyInput.value = false;
      }
  
      const handleDeleteSong = (index) => {
        selectedSongs.value.splice(index, 1)
      }
  
      const createSessionError = ref(false)
  
      const handleCreate = async () => {
        if (isCreateSession.value && selectedSongs.value.length === 3) {
          try{
            sessionsInfo.value = await getSessionsInfo();
            if (sessionsInfo.value.current_session !== null){
                if (sessionsInfo.value.current_session.loungeData.hexID){
                    const loungeId = sessionsInfo.value.current_session.loungeData.hexID
                    await createRanking(selectedSongs.value, loungeId)
                    router.push(`/dj/active-session/${loungeId}`);
                }
                else {
                  console.log('error')
                  router.push('/dj/login');
                }     
            }
            else {
              console.log('error')
              router.push('/dj/login');
            }
  
          }
          catch{
            console.log('error')
            router.push('/dj/login');
          } 
          
            
        } else if (isCreateGeneralVoting.value && selectedSongs.value.length === 4) {
          // precreate vote
          isLoading.value = true;
          console.log(selectedSongs.value)
          let songsToCreateVote = []
          selectedSongs.value.forEach(song => {
            var id = song.songId === undefined ? song.trackId : song.songId
            songsToCreateVote.push(id)
          })
          const response = await precreateVote(songsToCreateVote, loungeId);
          isLoading.value = false;
          if(response && response.result) {
            console.log('entra')
            router.go(-1)
          }
        } else {
          createSessionError.value = true
        }
        
      }
  
      onMounted(async () => {
        try {
          
          const songs = await getSuggestions();
          // const songs = await songsInfo(songIds);
          console.log(songs)
          const len = songs.length;
          
          hitSongs.value = songs.slice(0, Math.ceil(len / 2));
          popularSongs.value = songs.slice(Math.ceil(len / 2), len);
  
        } catch (error) {
          console.error('Error fetching popular songs:', error);
        }
      });
  
      return {
        searchSongs,
        searchLoading,
        searchTerm,
        handleSearchSong,
        handleCloseSongNavBar,
        handlePayment,
        timerSearch,
        popularSongs,
        hitSongs,
        openModal,
        closeModal,
        modalVisible,
        votePlans,
        handleSearchSongClick,
        resetEmptyInput,
        emptyInput,
        handleDeleteSong,
        handleCreate,
        createSessionError,
        selectedSongs,
        isCreateSession,
        isCreateGeneralVoting,
        precreateVote,
        loungeId,
        isLoading
      };
    }
  };
  </script>
  
  <style>
  .popular-songs-container {
    display: flex;
    gap: 16px;
    width: calc(10rem * var(--nSongs) + 16px * (var(--nSongs) + 1));
  }
  
  .overflow-popular-songs-container::-webkit-scrollbar {
    display: none;
  }
  
  .overflow-popular-songs-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: auto;
    white-space: nowrap;
    padding-left: 2rem;
  }
  </style>
  