<template>
  <div id="stripe-payment" class="flex flex-col h-full pt-10 pb-52 relative">
    <DJActionButton class="absolute top-8 left-10" :yellowbg="true" :image="arrowBack" @click="handleBack"/>
    <form class="flex-grow overflow-y-auto"> 
      <div id="payment-element"></div>
      <div id="link-authentication-element"></div>
      <div v-if="isLoading">
        <SimpleSpinner v-if="isLoading"/>
      </div>
    </form>

    <div class="fixed bottom-0 left-0 w-full px-10 py-6 bg-tp-dark-green flex flex-col justify-center items-center rounded-t-xl">
      <div class="flex flex-col gap-2">
        <SongCard v-if="isSongValid && songInfo.length > 0" :song="songInfo[0].songName" :artist="songInfo[0].artist" :image="songInfo[0].image" :payment="true" :votes=votes />
        <div class="w-80 flex flex-row p-2 justify-between font-extrabold leading-none text-tp-black">
          <p class="text-lg text-tp-yellow">Total a pagar</p>
          <p class="text-lg text-tp-yellow">{{ amount }}€</p>
        </div>
      </div>
      <button v-if="isAmountValid && isSongValid" @click="handleSubmit" class="w-80 py-3 px-4 bg-tp-yellow placeholder-tp-placeholder-black text-tp-black font-semibold rounded-2xl">
        Confirmar Pago
      </button>
    </div>
  </div>
</template>




<script>
import { loadStripe } from '@stripe/stripe-js';
import { ref, onMounted, computed } from 'vue';
import votingService from '@/service/votingService';
import SimpleSpinner from '@/components/Common/SimpleSpinner.vue';
import DJActionButton from '@/components/DJ/DJActionButton.vue';
import arrowBack from '@/assets/images/arrow-back.png';
import { useRoute, useRouter } from 'vue-router';
import { songsInfo } from '../../service/musicService';
import SongCard from '../AddSong/SongCard.vue';

export default {
  name: 'StripePayment',
  components: {
    DJActionButton,
    SimpleSpinner,
    SongCard
  },
  props: {
    paymentData: Object
  },
  setup (props, { emit }) {
    
    const elements = ref(null);
    const paymentMessage = ref("");
    const paymentMessageVisible = ref(false);
    const stripe = ref(null);
    const route = useRoute();
    const router = useRouter();
    const amount = route.params.votePlan;
    const amountStripe = route.params.votePlan*100;
    const loungeId = route.params.id;
    const flag = route.params.votationType;
    const songId = route.params.songId;
    let loungeVotingService = null;
    const isAmountValid = ref(amount > 0.9);
    const isSongValid = ref(false);
    const songInfo = ref([]);
    
    const isLoading = ref(false);
    const isButtonLoading = ref(false);

    const votes = computed(() => {
      let floatAmount = parseFloat(amount);
      if (floatAmount === 0.99) {
        return 3;
      } else if (floatAmount === 2.99) {
        return 10;
      } else if (floatAmount === 9.99) {
        return 40;
      } else {
        return 0; 
      }
    });

    const generatePayment = async () => {
      loungeVotingService = votingService(loungeId)
      const data = {
        amount: amountStripe,
        songId: songId
      }
      let response;
      if (flag === 'voting') {
        response = await loungeVotingService.votingPayment(data);
      } else if (flag === 'ranking') {
        response = await loungeVotingService.rankingPayment(data);
      }
      
      return response.clientSecret
    };

    const initialize = async () => {
      if (!isAmountValid.value) return;

      isSongValid.value = await checkSongValidity(songId);

      if (!isSongValid.value) return;

      //TODO: comprobar qué se quiere hacer cuando la amount y la song no sean válidas, retornar a la lounge?

      stripe.value = await loadStripe("pk_test_51Ope8FHdxAPIqguouk0aGbUpvBHbY7r0vuKmj3s3owFeip3lzUYprfbO2L4GRETz1lAexLUebIIlSAKTMZDspxmP004F5faPYU");

      const appearance = {
        theme: 'night',
        variables: {
          colorPrimary: '#021211',
          colorBackground: '#F9F871',
          colorText: '#133d33',
          fontFamily: 'Roboto, sans-serif',
          fontSize: '14px',
        },
        rules: {
          '.Input': {
            color: '#F9F871',
            backgroundColor: '#021211',
            fontSize: '16px',
            borderStyle: 'none'
          },
          '.AccordionItem': {
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 'bold',
          },
          '.Label': {
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            color: '#021211'
          }
        }
      };
      
      const clientSecret = await generatePayment(amountStripe);
      elements.value = stripe.value.elements({ appearance, loader: 'always', clientSecret });

      const paymentElementOptions = {
        layout: {
          type: 'accordion',
          spacedAccordionItems: true
        }
      };

      // email field
      const linkAuthenticationElement = elements.value.create("linkAuthentication");
      linkAuthenticationElement.mount("#link-authentication-element");

      const paymentElement = elements.value.create("payment", paymentElementOptions);
      paymentElement.mount("#payment-element");

      paymentElement.on('loaderstart', function() {
        isLoading.value = false;
      });

    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      isButtonLoading.value = true;

      const { error } = await stripe.value.confirmPayment({
        elements: elements.value,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: process.env.VUE_APP_FRONT_BASE_URL + `/lounge/${loungeId}/confirm-payment`,
        },
      });

      if (error) {
      // TODO
        // if (error.type === "card_error" || error.type === "validation_error") {
        //   this.showMessage(error.message);
        // } else {
        //   this.showMessage("An unexpected error occurred.");
        // }
      }

      isButtonLoading.value = false;
    };

    const checkSongValidity = async () => {
      try {
        // TODO REMOVE
        songInfo.value = await songsInfo(['1462966663']);
        console.log(songInfo.value[0])
        return songInfo.value.length > 0;
      } catch (error) {
        console.error("Error checking song validity", error);
        return false;
      }
    };

    const handleClose = () => {
      emit('close')
    };

    const handleBack = () => {
      router.go(-1)
    };
    onMounted(async () => {
      isLoading.value = true;
      initialize();
    });

    return {
      initialize,
      handleSubmit,
      generatePayment,
      handleClose,
      elements,
      isLoading,
      paymentMessage,
      paymentMessageVisible,
      stripe,
      amount,
      amountStripe,
      loungeId,
      isButtonLoading,
      flag,
      isAmountValid,
      isSongValid,
      checkSongValidity,
      songInfo,
      arrowBack,
      handleBack,
      votes
    }
  },
};
</script>

<style scoped lang="css">
/* Variables */
* {
  box-sizing: border-box;
}

form {
  width: 100%;
  align-self: center;
  padding: 40px;
  overflow-y: auto;
}

.hidden {
  display: none;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

#submit {
  margin-top: 22px;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    min-width: initial;
  }
}
</style>
