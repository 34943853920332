<template>
    <div class="modal-overlay" @click.self="handleCloseModal">
        <div class="relative">
            <div class="flex flex-col border-4 border-tp-light-green bg-tp-yellow rounded-2xl gap-6 p-7">
                <div class="flex flex-col gap-8">
                <img class="absolute w-4 h-4 top-3 right-3 z-10" :src="close" alt="close-icon" @click="handleCloseModal">
                <p class="text-tp-dark-green text-4xl font-bold">Accede a TuParty</p>
                <form class="flex flex-col gap-8" @submit.prevent="login">
                    <div class="flex flex-col items-start text-tp-dark-green font-bold gap-1">
                        <label class="text-sm" for="username">Email</label>
                        <div class="flex items-center gap-2">
                            <img :src=mail alt="" class="w-3.5 h-3.5">
                            <input class="bg-tp-yellow text-sm focus:outline-none focus:ring-0" type="text" id="username" v-model="credentials.username">
                        </div>
                        <img :src=line alt="" class="w-60">
                    </div>
                    <div class="flex flex-col items-start text-tp-dark-green font-bold gap-1">
                        <label class="text-sm" for="password">Contraseña</label>
                        <div class="flex items-center gap-2">
                            <img :src=lock alt="" class="w-3.5 h-3.5">
                            <input class="bg-tp-yellow text-sm focus:outline-none focus:ring-0" type="password" id="password" v-model="credentials.password">
                        </div>
                        <img :src=line alt="" class="w-60">
                    </div>
                    <button type="submit" class="bg-tp-dark-green text-tp-yellow font-bold text-lg px-20 py-2 rounded-full mt-4">Entrar</button>
                </form>
                </div>
                <p class="text-tp-dark-green text-xs text-center">¿No registrado? <a class="font-bold">Click aquí</a></p>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import mail from '@/assets/images/mail.png';
    import lock from '@/assets/images/lock.png';
    import line from '@/assets/images/green-line.png';
    import close from '@/assets/images/close-green.png';
    import {fakeLogin} from '../../service/djService';
    import { getSessionsInfo} from '../../service/djService';


    export default {
        name: 'DJLoginModal',
        setup(props, { emit }) {
            const router = useRouter();

            const credentials = ref({
                username: '',
                password: '',
            });

            const sessionsInfo = ref(null);

            
            const login = async () => {
                await fakeLogin();
                sessionsInfo.value = await getSessionsInfo();
                if (sessionsInfo.value.current_session !== null){
                    if (sessionsInfo.value.current_session.loungeData.hexID){
                        const loungeId = sessionsInfo.value.current_session.loungeData.hexID
                        router.push(`/dj/active-session/${loungeId}`);
                    }
                    else{
                        router.push('/dj/private-area');
                    }     
                }
                else{
                    router.push('/dj/private-area');
                }                
                console.log('Intento de login con:', credentials.value);
            };

            const handleCloseModal = () => {
                emit('onCloseModal');
            }

            return {
                login,
                credentials,
                mail,
                lock,
                line,
                close,
                handleCloseModal
            }
        }
    }
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>