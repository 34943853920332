<template>
  <div class="relative">
    <NavBar />
    <DJActionButton class="absolute xxs:top-26 top-20 left-8" :yellowbg="true" :image="arrowBack" @click="handleBack"/>
    <div class="flex flex-col gap-4 px-8 py-2">
      <p class="font-bold text-2xl">Ranking</p>      
      <div v-if = "timerIsNull"
      @click="startCountdown" 
       class="flex items-center bg-tp-dark-green border-2 border-tp-light-green gap-1 p-2 rounded-lg cursor-pointer mx-auto">
        <p class="font-bold">Lanzar cuenta atrás</p>
        <img :src="clock" alt="" class="w-4 h-4 text-tp-yellow">
      </div>
      <Timer v-if = "detailsLoaded && !timerIsNull" :initialTime="timeLeft" />
      <p v-if="timerIsNull" class="font-bold">¡Lanza la cuenta atrás para reproducir la canción que vaya primera!</p>
      <p v-if="!timerIsNull" class="font-bold">Deberás reproducir la canción que vaya primera cuando se acabe el contador y te avise.</p>
      <p v-if="isRankingFinished" class="font-bold">¡Debería estar sonando la canción que está redondeada en rojo!</p>
      <p v-if="noRanking" class="font-bold">Ahora mismo no hay ningún ranking activo</p>
      <DJRankingSongCard v-for="(rankingSong, index) in rankingSongs" 
        :key="index" 
        :rankingSong="rankingSong" 
        :index="index+1" 
        :rankingFinished ="isRankingFinished" 
        @deleteSong="handleOpenRejectSongModal(rankingSong.songId)"/>
      <DJShouldPlaySongModal v-if = "rankingSongs && rankingFinishedModal" :songToShow="rankingSongs[0]" @onCloseModal="handleCloseModal"/>
    </div>
    <DJConfirmRejectSong v-if="rejectSongModalVisible" :songId="songToReject" @onRejectSong="handleDeleteSong" @onCloseModal="handleCloseRejectModal"/>
    
  </div>


</template>

<script>
import NavBar from '@/components/Common/NavBar.vue';
import DJActionButton from '@/components/DJ/DJActionButton.vue';
import DJRankingSongCard from '@/components/DJ/DJRankingSongCard.vue';
import arrowBack from '@/assets/images/arrow-back.png';
import clock from '@/assets/images/clock.png';
import { useRouter, useRoute } from 'vue-router'
import { ref, onMounted, onBeforeUnmount} from 'vue';
import createLoungeService from '@/service/loungeService';
import { songsInfo } from '../../service/musicService';
import { rejectSong } from '../../service/djService';
import Timer from '@/components/Common/Timer.vue';
import DJShouldPlaySongModal from '../../components/DJ/DJShouldPlaySongModal.vue';
import DJConfirmRejectSong from '../../components/DJ/DJConfirmRejectSong.vue';
import { rankingCountdown } from '../../service/djService';


export default {
  name: 'DJRanking',
  components: {
    NavBar,
    DJActionButton,
    DJRankingSongCard,
    Timer,
    DJShouldPlaySongModal,
    DJConfirmRejectSong
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loungeId = route.params.id;
    const loungeDetails = ref(null);
    const cachedSongInfo = ref(new Map());
    const detailsLoaded = ref (false);
    const rankingSongs = ref(null);
    const timeLeft = ref (null);
    const timerIsNull = ref(null);
    const rankingFinishedModal = ref(false);
    const isRankingFinished = ref(false);
    const noRanking = ref (false);
    const pollingInterval = ref(null);
    const rejectSongModalVisible = ref(false);
    const songToReject = ref(null);

    const { fetchLoungeDetails } = createLoungeService();

    const handleBack = () => {
      router.go(-1);
    };

    const fetchDetails = async () => {
        if (!loungeId) return;
        const details = await fetchLoungeDetails(loungeId);
        if (details && details.public) {
          loungeDetails.value = details;
        }
        checkState();
        if (loungeDetails.value && loungeDetails.value.public && loungeDetails.value.public.ranking) {
            const songsData = Object.values(loungeDetails.value.public.ranking.songs);
            const songIds = songsData.map(song => song.songInfo.songID);  
            updateRanking(songsData, songIds);
            if (loungeDetails.value.public.ranking.timeLeft === null){
              timerIsNull.value = true;
            }
            else{
              timerIsNull.value = false;
              if(isRankingFinished.value === true){
                timeLeft.value = 180 + loungeDetails.value.public.ranking.timeLeft;

              }
              else{
                timeLeft.value = loungeDetails.value.public.ranking.timeLeft;
              }
              
            }
            detailsLoaded.value = true;
        }
    };

    const updateRanking = async (songsData, songIds) => {
        const newSongIds = songIds.filter(songId => !cachedSongInfo.value.has(songId));

        let newSongsResponse = [];
        if (newSongIds.length > 0) {
            newSongsResponse = await songsInfo(newSongIds);
            newSongsResponse.forEach(song => {
                cachedSongInfo.value.set(song.songId, song);
            });
        }

        const mergedSongsResponse = songIds.map(songId => cachedSongInfo.value.get(songId));
        refreshVotes(songsData, mergedSongsResponse);
    };

    const checkState = () => {
      if (loungeDetails.value.public && loungeDetails.value.public.status){
        if (loungeDetails.value.public.status === "RANKING" || loungeDetails.value.public.status === "FINISHED_GENERAL_VOTE"){
          isRankingFinished.value = false;
        }
        else if (loungeDetails.value.public.status === "FINISHED_RANKING"){
          if (isRankingFinished.value !== true){
            rankingFinishedModal.value =true;
          }
          isRankingFinished.value=true;
        }
        else{
          noRanking.value=true;
        }
      }

    }

    const handleCloseModal = () => {
      rankingFinishedModal.value = false;
    }

    const refreshVotes = (songsData, songsResponse) => {
        const votesMap = new Map(songsData.map(song => [song.songInfo.songID, song.votos]));
        const revenueMap = new Map(songsData.map(song => [song.songInfo.songID, song.revenue]));
        const mergedSongs = songsResponse.map(song => {
            const votes = votesMap.get(song.songId) || 0;
            const revenue = revenueMap.get(song.songId) || 0;
            return {
                ...song,
                votes: votes,
                revenue: revenue
            };
        });

        mergedSongs.sort((a, b) => b.votes - a.votes);
        rankingSongs.value = mergedSongs;
    };

    const startCountdown = ()=> {
      try{
        rankingCountdown(loungeId)
      }
      catch{
        console.log('error')
      }
    }

    onMounted(async () => {
        await fetchDetails(); 
        pollingInterval.value = setInterval(fetchDetails, 5000); 
    });

    onBeforeUnmount(() => {
            clearInterval(pollingInterval.value);
            pollingInterval.value = null; 
        });

    const handleDeleteSong = () => {
      rejectSong (songToReject.value, loungeId)
      rejectSongModalVisible.value = false;
    }

    const handleOpenRejectSongModal = (song) => {
      songToReject.value = song;
      rejectSongModalVisible.value = true;
    }

    const handleCloseRejectModal = () => {
      rejectSongModalVisible.value = false;
    }

    return {
      arrowBack,
      clock,
      handleBack,
      rankingSongs,
      handleDeleteSong,
      detailsLoaded,
      timerIsNull,
      timeLeft,
      rankingFinishedModal,
      handleCloseModal,
      isRankingFinished,
      noRanking,
      rejectSongModalVisible,
      songToReject,
      handleOpenRejectSongModal,
      handleCloseRejectModal,
      startCountdown
    }
  }
}
</script>