<template>
    <NavBar />
    <div class="flex flex-col gap-4 px-8 py-2">
        <div class="flex justify-between items-center z-20">
            <p class="font-extrabold text-2xl">Mejora el ambiente de la fiesta</p>
            <!-- <img @click="handlePrivateAreaRedirection" :src="settings" alt="" class="w-5 h-5 cursor-pointer"> -->
        </div>
        <p v-if="votingFinished || rankingFinished" class="font-extrabold">¡Debería estar sonando esta canción!</p>
        <DJRankingSongCard 
            v-if="votingFinished || rankingFinished" 
            :rankingSong="songToPlay" 
            :index="1" 
            :isGeneralVoting="true"
            :style="{ border: '2px solid red', borderRadius: '14px' }"
        />
        <DJGeneralVotingSection v-if="isLoaded" :publicData = "loungeDetails.public" :loungeId = "loungeId" :polls="polls" />
        <DJRankingSection v-if="isLoaded" :rankingData ="loungeDetails.public" :loungeId = "loungeId" />
        <DJShouldPlaySongModal v-if="finishedModal && songToPlay" :songToShow="songToPlay" @onCloseModal="handleCloseModal"/>
    </div>
</template>

<script>
import NavBar from '@/components/Common/NavBar.vue';
import DJGeneralVotingSection from '@/components/DJ/DJGeneralVotingSection.vue';
import DJRankingSection from '@/components/DJ/DJRankingSection.vue';
import settings from '@/assets/images/settings.png';
import { useRouter, useRoute } from 'vue-router';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import createLoungeService from '@/service/loungeService';
import { songsInfo } from '../../service/musicService';
import DJShouldPlaySongModal from '../../components/DJ/DJShouldPlaySongModal.vue';
import DJRankingSongCard from '@/components/DJ/DJRankingSongCard.vue';
import * as djService from '@/service/djService'

export default {
    name: 'DJActiveSession',
    components: {
        NavBar,
        DJGeneralVotingSection,
        DJRankingSection,
        DJShouldPlaySongModal,
        DJRankingSongCard
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const loungeId = route.params.id;
        const loungeDetails = ref(null);
        const isLoaded = ref(false);
        const state = ref(null);
        const { fetchLoungeDetails } = createLoungeService();
        const rankingFinished = ref(false);
        const votingFinished = ref(false);
        const finishedModal = ref(false);
        const songToPlay = ref(null);
        const pollingInterval = ref(null);
        const polls = ref(null)


        const fetchDetails = async () => {
            if (!loungeId){
                isLoaded.value=true;
                return;
            }
            const details = await fetchLoungeDetails(loungeId);
            if (details && details.public) {
                loungeDetails.value = details;
                state.value = details.public.status;
            }
            checkFinished();
            isLoaded.value = true;
        };

        const handlePrivateAreaRedirection = () => {
            router.push('/dj/private-area')
        }

        const checkFinished = async () => {
            if (loungeDetails.value.public && loungeDetails.value.public.status){
                if (loungeDetails.value.public.status === "FINISHED_GENERAL_VOTE"){
                    if (votingFinished.value !== true){
                        await getSongToPlay("voting");
                        finishedModal.value =true;
                    }
                    votingFinished.value = true;
                }
                else if (loungeDetails.value.public.status === "FINISHED_RANKING"){
                    if (rankingFinished.value !== true){
                        await getSongToPlay("ranking");
                        finishedModal.value =true;
                    }
                    rankingFinished.value = true;
                }
                else{
                    votingFinished.value = false;
                    rankingFinished.value = false;
                }
            }
        }

        const getSongToPlay = async (type) => {
            let songsData;
            if (type === "voting") {
                songsData = Object.values(loungeDetails.value.public.votingData.songs)
            } else {
                songsData = Object.values(loungeDetails.value.public.ranking.songs)
            }
            const firstSongId = songsData[0].songID;
            const songDetails = await songsInfo([firstSongId]);
            songToPlay.value =  songDetails[0];
            songToPlay.value.votes = songsData[0].votos;
            songToPlay.value.revenue = songsData[0].revenue;
        };

       

        onMounted(async () => {
            await fetchDetails();
            pollingInterval.value = setInterval(fetchDetails, 5000);
            polls.value = await djService.getPrecreateVote(loungeId)
        });

        onBeforeUnmount(() => {
            if (pollingInterval.value) {
                clearInterval(pollingInterval.value);
                pollingInterval.value = null;
            }
        });
            

        const handleCloseModal = () => {
            finishedModal.value = false;
        };
        
        return {
            settings,
            handlePrivateAreaRedirection,
            state,
            loungeDetails,
            isLoaded,
            loungeId,
            finishedModal,
            rankingFinished,
            votingFinished,
            handleCloseModal,
            songToPlay,
            djService,
            polls
        }
    }
}

</script>