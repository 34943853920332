<template>
    <div class="flex justify-between items-center bg-tp-yellow p-4 rounded-xl w-full">
      <div class="flex flex-col gap-1 text-tp-dark-green">
        <p v-for="(votingSong, index) in generalVoting.songs" :key="index">
          <span class="font-bold">{{ index }}.</span> {{ votingSong.name }} - {{ votingSong.artistName }}
        </p>
      </div>
      <div class="flex flex-col gap-3">
        <div class="flex justify-center items-center bg-tp-dark-green border-2 border-tp-light-green gap-1 p-2 rounded-lg" @click="launchGeneralVoting">
            <p class="font-bold">Cuenta atrás</p>
            <img :src="clock" alt="" class="w-4 h-4 text-tp-yellow">
        </div>
      </div>
    </div>
</template>

<script>
import clock from '@/assets/images/yellow-clock.png';
import pencil from '@/assets/images/pencil.png';

export default {
  name: 'DJPastSessions',
  props: {
    generalVoting: Object
  },
  setup(props, { emit }) {

    const launchGeneralVoting = () => {
      emit('launchGeneralVoting')
    }
    return {
      clock,
      pencil,
      launchGeneralVoting
    }
    }
  }

</script>