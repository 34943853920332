<template>
  <div class="flex flex-col items-start gap-4">
    <div class="flex gap-2 items-center">
      <p class="font-bold text-xl">Ranking</p>
      <img v-if="isActive" :src="live" alt="" class="w-5 h-5 animate-bounce">
    </div> 
    <div v-if="isActive" class="flex justify-between items-center bg-tp-yellow p-4 rounded-xl w-full">
      <div class="flex flex-col gap-1 text-tp-dark-green">
        <p class="font-bold">Top 3 actual:</p>
        <p v-for="(votingSong, index) in rankingSongs" :key="index">
          <span class="font-bold">{{ index + 1 }}.</span> {{ votingSong?.name }} - {{ votingSong?.artistName }}
          <span class="px-2 py-1 bg-tp-dark-green text-tp-yellow font-bold rounded-lg">{{ votingSong?.votes }}</span>
        </p>
      </div>
      <div v-if = "timerIsNull"
      @click="startCountdown"
       class="flex items-center bg-tp-dark-green border-2 border-tp-light-green gap-1 p-2 rounded-lg cursor-pointer">
        
        <p class="font-bold">Cuenta atrás</p>
        <img :src="clock" alt="" class="w-4 h-4 text-tp-yellow">
      </div>
      <Timer v-if = "!timerIsNull" :initialTime="timeLeft" />
    </div>
    <p v-else class="text-tp-yellow font-bold">
      No se puede acceder al ranking mientras haya una 4-hits activa
    </p>
    <DJControlPanel v-if="isActive" class="cursor-pointer" @click="handleControlPanelRedirection"/>
  </div>
</template>
  
<script>
  import DJControlPanel from './DJControlPanel.vue';
  import live from '@/assets/images/live.png';
  import clock from '@/assets/images/yellow-clock.png';
  import { useRouter } from 'vue-router';
  import Timer from '@/components/Common/Timer.vue';
  // import { songsInfo } from '../../service/musicService';
  import { ref,  onMounted, watch } from 'vue';
  import { rankingCountdown } from '../../service/djService';

  export default {
    name: 'DJRankingSection',
    props: {
      rankingData: Object,
      loungeId: String,
    },
    components: {
      DJControlPanel,
      Timer
    },
    setup(props) {
      const router = useRouter();
      const detailsLoaded = ref (false);
      const timeLeft = ref(null);
      // const cachedSongInfo = ref(new Map());
      const timerIsNull = ref (null);

      const rankingSongs = ref (null);

      const isActive = ref (false);

      const handleControlPanelRedirection = () => {
        router.push(`/dj/active-session/${props.loungeId}/ranking`);
      };

      const updateRanking = async (songsData) => {
        rankingSongs.value = songsData.map(song => {
          return {
            ...song.songInfo,
            votes: song.votos
          };
        })
      };

      const startCountdown = ()=> {
        try{
          rankingCountdown(props.loungeId)
        }
        catch{
          console.log('error')
        }
      }

      // const refreshVotes = (songsData, songsResponse) => {
      //   const votesMap = new Map(songsData.map(song => [song.songInfo.songID, song.votos]));
      //   const mergedSongs = songsResponse.map(song => {
      //     const votes = votesMap.get(song.songId) || 0;
      //     return {
      //       ...song,
      //       votes: votes
      //     };
      //   });

      //   mergedSongs.sort((a, b) => b.votes - a.votes);
      //   rankingSongs.value = mergedSongs.slice(0,3);
      // };

      onMounted(async () => {
          if (props.rankingData) {
            if (props.rankingData.status === "RANKING" || props.rankingData.status === "FINISHED_GENERAL_VOTE"){
              isActive.value = true;
              const songsData = Object.values(props.rankingData.ranking.songs);
              const songIds = songsData.map(song => song.songInfo.songID);
              updateRanking(songsData, songIds);
              if (props.rankingData.ranking.timeLeft === null){
                timerIsNull.value = true;
              }
              else{
                timerIsNull.value=false;
                timeLeft.value = props.rankingData.ranking.timeLeft;
              }
              detailsLoaded.value=true;
            }
            else{
              isActive.value = false;
            }
          }
        });

      watch(() => props.rankingData, (newRankingData) => {
        if (newRankingData) {
          if (newRankingData.status === "RANKING" || newRankingData.status === "FINISHED_GENERAL_VOTE"){
            isActive.value = true;
            const songsData = Object.values(newRankingData.ranking.songs);
            const songIds = songsData.map(song => song.songInfo.songID);

            if (songIds.length > 0) {
              updateRanking(songsData, songIds);
            }
            if (newRankingData.ranking.timeLeft === null){
              timerIsNull.value = true;
            } else{
              console.log("timeleft setted")            
              timeLeft.value = newRankingData.ranking.timeLeft;
              timerIsNull.value = false;
            }
          } else{
            console.log("ranking not active")
            isActive.value = false;
          }
        }
      }, { deep: true });

      return {
        live,
        clock,
        handleControlPanelRedirection,
        timeLeft,
        rankingSongs,
        detailsLoaded,
        timerIsNull,
        isActive,
        startCountdown
      };
    }
  }
  
</script>