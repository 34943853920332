<template>
    <div class="relative">
        <div :class="['flex justify-between items-center bg-tp-yellow py-2 px-5 rounded-xl', index === 1 && rankingFinished ? 'border-4 border-red-500' : '']">
            <div class="flex items-center gap-3 text-tp-dark-green">
                <p class="font-bold">{{ index }}</p>
                <img :src="rankingSong.image" alt="" class="w-12 h-12 rounded-full">
                <div class="flex flex-col">
                    <p class="font-bold text-lg leading-tight w-40 xs:w-52 truncate">{{ rankingSong.songName }}</p>
                    <p class="font-bold text-sm w-40 xs:w-52 truncate">{{ rankingSong.artist}}</p>
                    <p class="font-bold text-xs">{{rankingSong.revenue}} €</p>
                </div>
            </div>
            <div class="flex items-center justify-center w-14 rounded-full bg-tp-dark-green">
                <p class="font-bold text-sm py-1">{{ rankingSong.votes }}</p>
            </div>
        </div>
        <button v-if ="!isGeneralVoting" class="absolute -right-2 -top-3 w-8 h-8 rounded-full border-2 border-tp-yellow bg-tp-dark-green" @click="handleDeleteSong">-</button>
    </div>
</template>

<script>

export default {
    name: 'DJRankingSongCard',
    props: {
        rankingSong: Object,
        index: Number,
        isGeneralVoting: Boolean,
        rankingFinished: Boolean,
    },
    setup(props, { emit }) {
        const handleDeleteSong = () => {
            emit('deleteSong');
        }
        return {
            handleDeleteSong
        }
    }
}
</script>