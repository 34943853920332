<template>
  <div class="navbar" @click="redirectToUrl('')"></div>
</template>

<script>
export default {
  methods: {
    redirectToUrl(url) {
      if (url) {
        window.location.href = url;
      }
    }
  }
};
</script>