// import axios from 'axios';
// import jsonp from 'jsonp';
import createApiService from './apiService';

const apiService = createApiService();

// async function searchItunesTrack2(searchText) {
//   try {
//     const encodedSearchText = encodeURI(searchText.replace(/ /g, '+'));
//     const response = await axios.get(`https://itunes.apple.com/search?term=${encodedSearchText}&entity=song&attribute=keywordsTerm&limit=10&country=ES`);

//     const formattedData = response.data.results.map(song => ({
//       songId: song.trackId,
//       image: song.artworkUrl100.replace('100x100bb.jpg', '1000x1000bb.jpg'), 
//       artist: song.artistName,
//       songName: song.trackName
//     }));
    
//     return formattedData;
    
//   } catch (error) {
//     console.error("Error while calling iTunes API", error);
//     throw error;
//   }
// }

async function searchItunesTrack(searchTerm) {
  // SPOTIFY API, FIRST OUR BACKEND
  try {
    const url = `/search-sp-song`;
    const formData = new FormData();
    
    formData.append('searchTerm', searchTerm);

    const method = 'POST';
    
    const response = await apiService.request({
      method,
      url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    const formattedData = response.data.result.map(song => ({
      songId: song.id,
      image: song.cover, 
      artist: song.artist,
      songName: song.name
    }));
    
    return formattedData;
    
  } catch (error) {
    console.error("Error while calling iTunes API", error);
    throw error;
  }
}

async function songsInfo(songs) {
  try {
    const formattedData = songs.map(song => ({
      songId: song.songID,
      image: song.image, 
      artist: song.artistName,
      songName: song.name
    }));
    return formattedData
  } catch (error) {
    console.error("Error while calling iTunes API", error);
    throw error;
  }
}

// async function songsInfo(songIds) {
//   try {
//     const songIdsWithCommas = songIds.join(',');
//     return new Promise((resolve, reject) => {
//       jsonp(`https://itunes.apple.com/lookup?id=${songIdsWithCommas}&entity=song`, (err, data) => {
//         if (err) {
//           reject(err);
//         } else {
//           // Siempre mismo formato por si cambia la api de music
//           const formattedData = data.results.map(song => ({
//             songId: song.trackId,
//             image: song.artworkUrl100.replace('100x100bb.jpg', '1000x1000bb.jpg'), 
//             artist: song.artistName,
//             songName: song.trackName
//           }));
//           resolve(formattedData);
//         }
//       });
//     });
//   } catch (error) {
//     console.error("Error while calling iTunes API", error);
//     throw error;
//   }
// }

async function getSuggestions(){

  try {
    const response = await apiService.get('/suggestions');
    const suggestions = response.data.result;
    return suggestions;

  } catch (error) {
    console.error('Error fetching suggestions:', error);
    throw error;
  }

}



export { searchItunesTrack, songsInfo, getSuggestions };
